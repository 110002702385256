import React, { useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Light from '../../assets/light_squere.webp';
import Dark from '../../assets/dark_squere.webp';
import SecondImage from './SecondImage';
import { Header, Paragraph, StyledLi, Title } from '../common/Typography';

const OfferWrapper = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    position: relative;
`;

const Wrapper = styled.div`
    width: 300vw;
    height: 60vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-top: 6vh;
`;

const Container = styled.div`
    width: 100vw;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BdgWrapper = styled.img`
    width: 15vw;
    height: 15vw;
    position: absolute;
    top: 30%;
    left: 65%;
    z-index: 1;
`;

const DescWrapper = styled.div`
    width: 15vw;
    height: 15vw;
    position: absolute;
    top: 30%;
    left: 65%;
    z-index: 2;
    justify-content: center;
    font-size: 4.5vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 600;
    color: #343434;
`;

const Pined = styled.div`
    height: 100vh;
    display: flex;
    background-color: white;
`;

const Spacer2 = styled.div`
    height: 100vh;
    margin-top: 14vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const StyledParagraph = styled(Paragraph)`
    width: 40vw;
    align-self: center;
`;

const Spacer = styled.div`
    width: 30vw;
`;

interface Props {
    scroller: any;
}

const Offer = ({ scroller }: Props) => {
    useEffect(() => {
        if (scroller) {
            const moveFrom = (1 / 3) * 100;
            const moveTo = -(2 / 3) * 100;

            const animation = gsap
                .timeline()
                .fromTo('#test1', { xPercent: moveFrom }, { xPercent: moveTo, ease: 'linear' });

            ScrollTrigger.create({
                animation,
                trigger: '#offert1',
                endTrigger: '#sp1',
                scrub: true,
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: '#offert1',
                    endTrigger: '#sp1',
                    pin: '#pined',
                },
            });

            gsap.fromTo(
                '#para1',
                {
                    opacity: 1,
                    scale: 1,
                },
                {
                    opacity: 0,
                    scale: 0.7,
                    scrollTrigger: {
                        trigger: '#offert1',
                        start: 'bottom 85%',
                        end: 'bottom 70%',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#para2',
                {
                    opacity: 1,
                    scale: 1,
                },
                {
                    opacity: 0,
                    scale: 0.7,
                    scrollTrigger: {
                        trigger: '#sp1',
                        start: 'bottom 85%',
                        end: 'bottom 70%',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#para1',
                {
                    opacity: 0,
                    scale: 0.7,
                },
                {
                    opacity: 1,
                    scale: 1,
                    scrollTrigger: {
                        trigger: '#offert1',
                        start: 'top 20%',
                        end: 'top',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#para2',
                {
                    opacity: 0,
                    scale: 0.7,
                },
                {
                    opacity: 1,
                    scale: 1,
                    scrollTrigger: {
                        trigger: '#sp1',
                        start: 'top 20%',
                        end: 'top',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#para3',
                {
                    opacity: 0,
                    scale: 0.7,
                },
                {
                    opacity: 1,
                    scale: 1,
                    scrollTrigger: {
                        trigger: '#sp2',
                        start: 'top 40%',
                        end: 'top',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#desc_1',
                { opacity: 1, scale: 1 },
                {
                    opacity: 0,
                    scale: 1.3,
                    scrollTrigger: {
                        trigger: '#offert1',
                        start: 'bottom 85%',
                        end: 'bottom 50%',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#desc_1',
                { opacity: 0, scale: 1.3 },
                {
                    opacity: 1,
                    scale: 1,
                    scrollTrigger: {
                        trigger: '#offert1',
                        start: 'top 50%',
                        end: 'top',
                        scrub: true,
                    },
                }
            );

            gsap.fromTo(
                '#l_sqr',
                { opacity: 0 },
                {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: '#offert1',
                        start: 'top 60%',
                        end: 'top 20%',
                        scrub: true,
                    },
                }
            );

            gsap.timeline({
                scrollTrigger: {
                    trigger: '#sp2',
                    start: 'top 80%',
                    end: 'top 0%',
                    scrub: true,
                },
            })
                .add('grp')
                .fromTo(
                    '#d_sqr',
                    { opacity: 1 },
                    {
                        opacity: 0,
                    },
                    'grp'
                )
                .fromTo(
                    '#pined',
                    { backgroundColor: '#cdcbd1' },
                    {
                        backgroundColor: '#fff',
                    },
                    'grp'
                )
                .fromTo('#offer_title', { color: '#fff' }, { color: '#4d4b4b' }, 'grp')
                .fromTo('#desc_2', { opacity: 1, scale: 1 }, { opacity: 0, scale: 1.3 }, 'grp')
                .fromTo('#desc_3', { opacity: 0, scale: 1.3 }, { opacity: 1, scale: 1 });

            gsap.timeline({
                scrollTrigger: {
                    trigger: '#sp1',
                    start: 'top 80%',
                    end: 'top 0%',
                    scrub: true,
                },
            })
                .fromTo(
                    '#d_sqr',
                    { opacity: 0 },
                    {
                        opacity: 1,
                    }
                )
                .fromTo(
                    '#pined',
                    { backgroundColor: '#fff' },
                    {
                        backgroundColor: '#cdcbd1',
                    }
                )
                .fromTo('#offer_title', { color: '#4d4b4b' }, { color: '#fff' })
                .fromTo(
                    '#desc_2',
                    { color: '#fff', opacity: 0, scale: 1.3 },
                    { opacity: 1, scale: 1 }
                );
        }
    }, [scroller]);

    return (
        <OfferWrapper id={'offert1'}>
            <Pined id={'pined'}>
                <Spacer2>
                    <Title id={'offer_title'} className={'animIn'}>
                        OFERTA
                    </Title>
                    <BdgWrapper id={'l_sqr'} src={Light} alt={'light'} />
                    <BdgWrapper id={'d_sqr'} src={Dark} alt={'dark'} />
                    <DescWrapper id={'desc_1'}>Usługi księgowe</DescWrapper>
                    <DescWrapper id={'desc_2'}>Usługa kadrowo- płacowa</DescWrapper>
                    <DescWrapper id={'desc_3'}>Rozliczenia podatkowe</DescWrapper>
                    <Wrapper id={'test1'}>
                        <Container id={'p1'} className={'panel'}>
                            <StyledParagraph id={'para1'}>
                                <StyledLi>
                                    Prowadzenie ksiąg handlowych (tzw. pełna księgowość)
                                </StyledLi>
                                <StyledLi>
                                    Prowadzenie podatkowej księgi przychodów i rozchodów (tzw. mała
                                    księgowość)
                                </StyledLi>
                                <StyledLi>Rozliczanie ryczałtu ewidencjonowanego</StyledLi>
                                <StyledLi>Prowadzenie rejestrów zakupu i sprzedaży VAT</StyledLi>
                                <StyledLi>
                                    Prowadzenie ewidencji środków trwałych i wyposażenia
                                </StyledLi>
                                <StyledLi>Sporządzanie sprawozdań finansowych</StyledLi>
                            </StyledParagraph>
                            <Spacer />
                        </Container>
                        <Container id={'p2'} className={'panel'}>
                            <StyledParagraph id={'para2'}>
                                <StyledLi>
                                    Prowadzenie pełnej dokumentacji związanej z zatrudnieniem
                                    pracowników
                                </StyledLi>
                                <StyledLi>Przygotowanie listy płac</StyledLi>
                                <StyledLi>
                                    Sporządzanie umów o pracę, umów zlecenia i o dzieło oraz
                                    świadectw pracy, a także dokumentacji związanej z zasiłkami
                                    opiekuńczymi, macierzyńskimi czy też chorobowymi i innych
                                    zaświadczeń związanych z zatrudnieniem
                                </StyledLi>
                                <StyledLi>
                                    Przygotowywanie aneksów do umów bądź też wypowiedzeń
                                </StyledLi>
                                <StyledLi>
                                    Rozliczanie umów z pracownikami, w tym także zlecenia oraz o
                                    dzieło
                                </StyledLi>
                                <StyledLi>Sporządzanie dokumentacji i rozliczeń z ZUS</StyledLi>
                                <StyledLi>Pomoc przy kontrolach z ZUS i Urzędu Skarbowego</StyledLi>
                                <StyledLi>
                                    Sporządzanie obowiązkowych sprawozdań statystycznych i wysyłanie
                                    ich do GUS
                                </StyledLi>
                                <StyledLi>Roczne rozliczenia pracowników</StyledLi>
                                <StyledLi>
                                    Wyliczanie podatku dochodowego osób zatrudnionych
                                </StyledLi>
                            </StyledParagraph>
                            <Spacer />
                        </Container>
                        <Container id={'p3'} className={'panel'}>
                            <StyledParagraph id={'para3'}>
                                <StyledLi>
                                    Podatek od towarów i usług (VAT) – powszechny podatek obrotowy
                                    od towarów i usług
                                </StyledLi>
                                <StyledLi>
                                    Ryczałt od przychodów ewidencjonowanych – uproszczona forma
                                    opodatkowania przedsiębiorstwa
                                </StyledLi>
                                <StyledLi>
                                    Podatek dochodowy od osób fizycznych (PIT) – rodzaj podatku
                                    bezpośredniego obejmujący dochody uzyskane przez osoby fizyczne
                                </StyledLi>
                                <StyledLi>
                                    Podatek dochodowy od osób prawnych (CIT) – podatek bezpośredni
                                    odprowadzany od dochodów spółek i osób prawnych
                                </StyledLi>
                            </StyledParagraph>
                            <Spacer />
                        </Container>
                    </Wrapper>
                    <SecondImage scroller={scroller} />
                </Spacer2>
            </Pined>
        </OfferWrapper>
    );
};
export default Offer;
