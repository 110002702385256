import React, { useEffect } from 'react';
import styled from 'styled-components';
import mainImg from '../../assets/001.webp';
import gsap from 'gsap';

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

const NameContainer = styled.div`
    display: flex;
    position: fixed;
    margin-top: 25vh;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    z-index: 99;
    font-weight: 600;
    color: #343434;
`;

const GrayCurve = styled.div`
    display: flex;
    height: 100vh;
    background-color: #cdcbd1;
    position: absolute;
    width: 200%;
    transform: translateX(-20%);
    rotate: -1deg;
    transform-origin: top left;
`;

const ShadowCurve = styled.div`
    display: flex;
    height: 30vh;
    background-color: black;
    opacity: 0.4;
    position: absolute;
    width: 200%;
    transform: translateX(-20%);
    rotate: -2deg;
    transform-origin: top left;
`;

const TitleWrapper = styled.div`
    display: flex;
    font-size: 6em;
    flex-direction: row;
    justify-content: center;
`;

const ImgWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 95vh;
    overflow: hidden;
`;

const ImgMain = styled.img`
    margin-top: 100px;
    height: 130vh;
    width: 100%;
    object-fit: cover;
    opacity: 0;
`;

interface Props {
    scroller: any;
}

const Home = ({ scroller }: Props) => {
    useEffect(() => {
        if (scroller) {
            gsap.to('#img001', {
                yPercent: -30,
                scrollTrigger: {
                    trigger: '#home',
                    start: 'top 10%',
                    scrub: true,
                },
            });

            gsap.to('#img001', {
                opacity: 1,
                duration: 1,
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: '#cnt_w',
                    start: 'top bottom',
                    end: 'top -30%',
                    scrub: true,
                },
            })
                .add('curve')
                .to(
                    '#cnt1',
                    {
                        rotate: '-6deg',
                    },
                    'curve'
                )
                .to(
                    '#cnt2',
                    {
                        rotate: '-7deg',
                    },
                    'curve'
                );

            gsap.timeline()
                .add('hed')
                .fromTo(
                    '#n1',
                    { opacity: 0 },
                    {
                        opacity: 1,
                        duration: 1,
                        delay: 0.2,
                        ease: 'power4.in',
                    },
                    'hed'
                )
                .fromTo(
                    '#n2',
                    { opacity: 0 },
                    { opacity: 1, duration: 1.3, delay: 0.4, ease: 'power4.in' },
                    'hed'
                );
        }
    }, [scroller]);

    return (
        <Container id={'home'}>
            <NameContainer id={'nc'}>
                <TitleWrapper id={'n1'}>MB</TitleWrapper>
                <TitleWrapper id={'n2'}>— BIURO USŁUG KSIĘGOWYCH —</TitleWrapper>
            </NameContainer>
            <ImgWrapper id={'wrapper001'}>
                <ImgMain id={'img001'} src={mainImg} alt={'001'} />
            </ImgWrapper>
            <div id={'cnt_w'}>
                <ShadowCurve id={'cnt2'} />
                <GrayCurve id={'cnt1'} />
            </div>
        </Container>
    );
};

export default Home;
