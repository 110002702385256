import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { Paragraph, SubmitButton } from '../common/Typography';
import axios from 'axios';

const Container = styled.div`
    margin-top: 5vh;
    width: 80%;
    display: flex;
    flex-direction: column;
`;

const StyledPara = styled(Paragraph)`
    color: #fff;
    min-height: 2vh;
    margin-bottom: 1vh;
`;

const ContactForm = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string | undefined>();
    const [trySend, setTrySend] = useState(false);
    const [resultMessage, setResultMessage] = useState<string>('');
    const [send, setSend] = useState(false);

    const onSend = () => {
        setTrySend(true);
        if (name === '' || !/\S+@\S+\.\S+/.test(email) || message === undefined) {
            setResultMessage('Czegoś brakuje w formularzu');
        } else if (!send) {
            document.body.style.cursor = 'wait';
            setResultMessage('');
            setSend(true);
            axios
                .post('https://m-bender.pl/php/mailer_new.php', {
                    // eslint-disable-next-line camelcase
                    contact_name: name,
                    // eslint-disable-next-line camelcase
                    contact_email: email,
                    message: message,
                })
                .then(() => setResultMessage('Twoja wiadomość została wysłana'))
                .catch(err => {
                    setResultMessage('Coś poszło nie tak. Spróbuj ponownie później');
                    console.log(err);
                })
                .finally(() => (document.body.style.cursor = 'default'));
        }
    };

    return (
        <Container>
            <TextField
                style={{ marginBottom: '2vh' }}
                required
                id='name-input'
                label='imię'
                value={name}
                error={trySend && name === ''}
                onChange={e => setName(e.target.value)}
            />
            <TextField
                style={{ marginBottom: '2vh' }}
                required
                id='email-input'
                label='e-mail'
                value={email}
                error={trySend && email !== undefined && !/\S+@\S+\.\S+/.test(email)}
                onChange={e => setEmail(e.target.value)}
            />
            <TextField
                required
                id='message-input'
                label='wiadomość'
                value={message}
                onChange={e => setMessage(e.target.value)}
                rows={10}
                error={trySend && message === undefined}
                multiline
            />
            <StyledPara>{resultMessage}</StyledPara>
            <SubmitButton disabled={send} onClick={onSend}>
                Wyślij
            </SubmitButton>
        </Container>
    );
};

export default ContactForm;
