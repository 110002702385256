import styled from 'styled-components';
import { fonts } from '../../assets/fonts/fonts';

export const Title = styled.div`
    font-size: 4vh;
    color: #fff;
    font-weight: 600;
    align-self: center;
`;

export const Header = styled.div`
    font-size: 3.5vh;
    color: #4d4b4b;
    font-weight: 600;
`;

export const Paragraph = styled.div`
    margin-top: 2vh;
    font-size: 1.8vh;
    color: #4d4b4b;
    font-weight: 400;
    text-align: justify;
`;

export const StyledLi = styled.li`
    margin-bottom: 0.5vh;
`;

export const SubmitButton = styled.div<{ disabled: boolean }>`
    font-size: 1.8vh;
    background-color: ${props => (props.disabled ? '#898893' : '#7f778f')};
    color: #fff;
    padding: 1vh;
    border-radius: 10px;
    width: fit-content;
    transition: 0.3s;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};

    &:hover {
        background-color: ${props => (props.disabled ? '#898893' : '#5d556c')};
    }
`;

export const TextButton = styled.div`
    font-family: ${fonts.main};
    margin: 1vw;
    display: flex;
    height: fit-content;
    font-size: 3vh;
    color: #afafb6;
    padding: 4px;
    align-self: center;
    transition: 0.3s;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: #dfdfe1;
    }
`;
