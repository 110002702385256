import React from 'react';
import styled from 'styled-components';
import { Paragraph, TextButton, Title } from '../common/Typography';
import LocationMap from './LocationMap';
import Comarch from '../../assets/comarch.webp';
import ContactForm from './ContactForm';
import Modals from './Modals';
import FB from '../../assets/fb.webp';
import Insta from '../../assets/instagram.webp';

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    position: relative;
    align-items: center;
`;

const Wrapper = styled(Title)`
    margin-top: 18vh;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 70%;
    margin-top: 5vh;
`;

const InRowWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const SideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: space-between;
`;

const StyledParagraph = styled(Paragraph)`
    font-weight: 600;
    margin-bottom: 1.3vh;
`;

const SubParagraph = styled.div`
    font-size: 1.4vh;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0.5vh;
    margin-left: 0.3vw;
    transition-duration: 0.3s;
`;

const LinkedSubParagraph = styled(SubParagraph)`
    &:hover {
        color: #dfdfe1;
    }
`;

const StyledImg = styled.img`
    margin-top: 4vh;
    width: 80%;
`;

const MiniImg = styled.img`
    margin-top: 2vh;
    width: 1.5vw;
    margin-right: 0.5vw;
    cursor: pointer;
`;

const StyledLink = styled.a`
    text-decoration: none;
`;

const StyledTextButton = styled(TextButton)`
    color: white;
    font-size: 1.5vh;
    margin: 0 2vh 0 0;
`;

interface Props {
    setOpenCookies: (state: boolean) => void;
    setOpenRODO: (state: boolean) => void;
}

const Contact = ({ setOpenCookies, setOpenRODO }: Props) => {
    return (
        <Container id={'contact'}>
            <Wrapper className={'animIn'}>KONTAKT</Wrapper>
            <RowWrapper>
                <SideWrapper>
                    <InRowWrapper>
                        <SideWrapper className={'animIn'}>
                            <div>
                                <StyledParagraph>Godziny otwarcia</StyledParagraph>
                                <SubParagraph>pn-wt: 8.00 - 16.00</SubParagraph>
                                <SubParagraph>śr: 10.00 - 16.00</SubParagraph>
                                <SubParagraph>czw-pt: 8.00 - 16.00</SubParagraph>
                            </div>
                            <StyledImg src={Comarch} alt={'comarch'} />
                        </SideWrapper>
                        <SideWrapper className={'animIn'}>
                            <StyledParagraph>Adres</StyledParagraph>
                            <SubParagraph>al. Śliwowa 7b/6</SubParagraph>
                            <SubParagraph>54-106 Wrocław</SubParagraph>
                            <StyledParagraph>E-mail</StyledParagraph>
                            <StyledLink href='mailto:ksiegowosc@m-bender.pl'>
                                <LinkedSubParagraph>ksiegowosc@m-bender.pl</LinkedSubParagraph>
                            </StyledLink>
                            <StyledParagraph>Telefon</StyledParagraph>
                            <StyledLink href={'tel:+48531405500'}>
                                <LinkedSubParagraph>531 405 500</LinkedSubParagraph>
                            </StyledLink>
                            <InRowWrapper>
                                <a
                                    href='https://www.facebook.com/people/MB-Biuro-Us%C5%82ug-Ksi%C4%99gowych/100047378925707/'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <MiniImg src={FB} alt={'FB'} />
                                </a>
                                <a
                                    href='https://www.instagram.com/mb.biuro.uslug.ksiegowych/'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <MiniImg src={Insta} alt={'Instagram'} />
                                </a>
                            </InRowWrapper>
                        </SideWrapper>
                    </InRowWrapper>
                    <LocationMap />
                </SideWrapper>
                <SideWrapper className={'animIn'}>
                    <ContactForm />
                    <InRowWrapper>
                        <StyledTextButton onClick={() => setOpenCookies(true)}>
                            POLITYKA COOKIES
                        </StyledTextButton>
                        <StyledTextButton onClick={() => setOpenRODO(true)}>
                            KLAUZULA INFORMACYJNA RODO
                        </StyledTextButton>
                    </InRowWrapper>
                </SideWrapper>
            </RowWrapper>
        </Container>
    );
};

export default Contact;
