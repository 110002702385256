import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { fonts } from '../../assets/fonts/fonts';
import { Box, Modal, Typography } from '@mui/material';
import { TextButton } from '../common/Typography';
import styled from 'styled-components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
`;

interface Props {
    openCookies: boolean;
    openRODO: boolean;
    setOpenCookies: (state: boolean) => void;
    setOpenRODO: (state: boolean) => void;
}

const Modals = ({ openCookies, openRODO, setOpenRODO, setOpenCookies }: Props) => {
    return (
        <Container>
            <Modal
                open={openRODO}
                onClose={() => setOpenRODO(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <Typography id='modal-modal-title' variant='h6' component='h2'>
                        KLAUZULA INFORMACYJNA RODO
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        Zgodnie z art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i
                        Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                        fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                        swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                        rozporządzenie o ochronie danych), zwane dalej RODO, informujemy, że:
                        <ol type={'1'}>
                            <li>
                                Inspektorem Ochrony Danych Osobowych jest: Paweł Moskwa,
                                inspektor@m-bender.pl
                            </li>
                            <li>
                                Pani/Pana dane osobowe będą chronione i przetwarzane w celu:
                                <ol type={'a'}>
                                    <li>
                                        prowadzenia z Panią/Panem korespondencji i obsługi sprawy,
                                        której ona dotyczy oraz w celu ewentualnego dochodzenia
                                        roszczeń lub obrony przed takimi roszczeniami – podstawą
                                        prawną jest prawnie uzasadniony interes ADO zgodnie z art. 6
                                        ust. 1 lit f RODO – tj. obsługa spraw zgłoszonych przez
                                        formularz, prowadzenie korespondencji z zainteresowanymi
                                        podmiotami i obsługa zgłaszanych przez nich spraw;
                                    </li>
                                    <li>
                                        przestawienia oferty, w związku umożliwienia złożenia i
                                        realizacji zamówień oraz zawierania umów sprzedaży produktów
                                        oferowanych przez ADO za pośrednictwem Serwisu (na podstawie
                                        art. 6 ust. 1 lit. (b) RODO), jeżeli zapytanie wysłane przez
                                        formularz zawierało prośbę o ofertę,
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Pani/Pana dane osobowe będą przechowywane przez okres niezbędny do
                                realizacji celu, na który wyraziła Pani/Pan zgodę, z systematyczną
                                kontrolą oceny ich przydatności, nie dłużej niż co 3 lata od daty
                                ich wprowadzenia. Okres przetwarzania danych osobowych może zostać
                                każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli
                                przetwarzanie danych osobowych będzie niezbędne dla dochodzenia
                                ewentualnych roszczeń lub obrony przed takimi roszczeniami przez
                                ADO. W zakresie, w jakim dane przetwarzane są w celu kierowania do
                                Pani/Pana treści marketingowych, będą one przetwarzane do czasu
                                wycofania zgody lub wniesienia sprzeciwu względem takiego
                                przetwarzania.
                            </li>
                            <li>
                                Pani/Pana dane nie będą przekazywane innym podmiotom poza:
                                dostawcami systemów informatycznych dla ADO, podmiotami świadczącymi
                                na rzecz ADO usługi niezbędne do wykonania zawieranej z Panią/Panem
                                umowy, w tym usługi prawne, operatorami pocztowymi i kurierami,
                                bankami w zakresie realizacji płatności, niektórymi osobom
                                wykonującym regulowane zawody, takim jak prawnicy.
                            </li>
                            <li>
                                Przysługuje Pani/Panu prawo: dostępu do treści danych osobowych, w
                                tym żądania wydania ich kopii oraz żądania ich sprostowania,
                                usunięcia danych osobowych (prawo do bycia zapomnianym),
                                ograniczenia przetwarzania danych osobowych, prawo do przenoszenia
                                danych osobowych do innego administratora, prawo wniesienia
                                sprzeciwu względem przetwarzania danych osobowych, w tym
                                profilowania, oraz na potrzeby marketingu bezpośredniego, do
                                cofnięcia zgody w przypadku, gdy ADO będzie przetwarzać Pani/Pana
                                dane osobowe w oparciu o zgodę, w dowolnym momencie i w dowolny
                                sposób, bez wpływu na zgodność z prawem przetwarzania, którego
                                dokonano na podstawie zgody przed jej wycofaniem. Przysługuje
                                Pani/Panu prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych
                                Osobowych, gdy uzna Pani/Pan, że przetwarzanie danych osobowych
                                narusza przepisy RODO.
                            </li>
                            <li>
                                Przysługuje Pani/Panu prawo wniesienia sprzeciwu względem
                                przetwarzania danych osobowych w celu kierowania do Pani/Pana treści
                                marketingowych. Ponadto względem przetwarzania danych osobowych dla
                                celów wynikających z prawnie usprawiedliwionych interesie ADO
                                przysługuje Pani/Panu prawo wniesienia sprzeciwu z przyczyn
                                związanych z Pani/Pana szczególną sytuacją.
                            </li>
                            <li>
                                Podanie przez Pana/Panią danych osobowych jest dobrowolne w zakresie
                                niezbędnym do udzielenia informacji.
                            </li>
                        </ol>
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={openCookies}
                onClose={() => setOpenCookies(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <Typography id='modal-modal-title' variant='h6' component='h2'>
                        Informacje o „Cookies”
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        <ol type={'1'}>
                            <li>
                                „Cookies” (ciasteczka) to informacje tekstowe zawierające dane
                                przechowywane w urządzeniach użytkowników, przeznaczone do
                                korzystania ze stron internetowych. Pozwalają m.in. zidentyfikować
                                rodzaj urządzenia użytkownika i dopasować stronę do indywidualnych
                                preferencji. Pliki „Cookies” zawierają najczęściej adres strony
                                internetowej, czas przechowywania na urządzeniu użytkownika oraz
                                własny unikatowy identyfikator.
                            </li>
                            <li>
                                „Cookies” używamy w celu zbierania danych statystycznych o
                                odwiedzinach za pomocą serwisu Google Analytics. Daje to możliwość
                                ulepszania naszej strony.
                            </li>
                            <li>
                                „Cookies” stosowane są także przez aplikacje internetowe dostępne w
                                naszym serwisie takie jak: formularz rejestracyjny, panel
                                internetowy, centrum serwisowe. W tym przypadku „cookies” potrzebne
                                są do utrzymywania sesji aplikacji i są niezbędne do ich poprawnego
                                działania.
                            </li>
                            <li>
                                Stosujemy „Cookies” sesyjne (tymczasowe) oraz stałe.„Cookies”
                                sesyjne przechowywane są na urządzeniu użytkownika do wylogowania ze
                                strony internetowej lub wyłączenia przeglądarki internetowej.
                                „Cookies” stałe przechowywane są przez zdefiniowany czas, który
                                określany jest parametr zawarty w pliku „cookies” - użytkownik
                                dysponuje możliwością ich ręcznego usunięcia.
                            </li>
                            <li>
                                Oprogramowanie wykorzystywane do przeglądania stron WWW domyślnie
                                dopuszcza umieszczanie plików „Cookies” na urządzeniu końcowym.
                                Użytkownik może dokonać właściwej konfiguracji przeglądarki, aby
                                zablokować automatyczne akceptowanie plików „Cookies” lub uzyskać
                                każdorazową informację o przesłaniu pliku na urządzenie użytkownika.
                                Właściwe informacje o obsłudze plików „Cookies” oraz możliwych
                                konfiguracjach dostępne są w pomocy przeglądarki (dostępnej
                                przeważnie po naciśnięciu klawisza F1 na klawiaturze w czasie pracy
                                z przeglądarką). Poziom ograniczeń dotyczący stosowania plików
                                „cookies” może wpłynąć na dostępność i funkcji na naszych stronach
                                internetowych i aplikacjach, aż do zablokowania możliwości ich
                                prawidłowego użytkowania.
                                <ol type={'a'}>
                                    <li>
                                        <a
                                            href='https://support.google.com/chrome/answer/95647?hl=pl'
                                            target='_blank'
                                            rel='noreferrer'>
                                            Chrome
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek&redirectlocale=pl'
                                            target='_blank'
                                            rel='noreferrer'>
                                            Firefox
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://support.microsoft.com/en-us/windows/change-or-reset-internet-explorer-settings-2d4bac50-5762-91c5-a057-a922533f77d5'
                                            target='_blank'
                                            rel='noreferrer'>
                                            Internet Explorer
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac'
                                            target='_blank'
                                            rel='noreferrer'>
                                            Safari
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://help.opera.com/en/latest/web-preferences/#cookies'
                                            target='_blank'
                                            rel='noreferrer'>
                                            Opera
                                        </a>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </Typography>
                </Box>
            </Modal>
            <CookieConsent
                location='bottom'
                buttonText='Przyjmuję do wiadomości'
                cookieName='cookie-accept'
                style={{
                    fontStyle: fonts.main,
                    fontSize: '1.5vh',
                    background: '#7f778f',
                }}
                buttonStyle={{
                    borderRadius: '10px',
                    backgroundColor: '#cdcbd1',
                    color: '#4d4b4b',
                    fontSize: '1.5vh',
                    fontStyle: fonts.main,
                    padding: '1vh',
                }}
                expires={90}>
                Ta strona używa ciasteczek (cookies), do zbierania statystyk odwiedzin oraz do
                utrzymywania sesji aplikacji internetowych.{' '}
                <span
                    onClick={() => setOpenCookies(true)}
                    style={{
                        backgroundColor: '#cdcbd1',
                        color: '#4d4b4b',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        padding: '1vh',
                        fontSize: '1.5vh',
                        marginLeft: '2vw',
                    }}>
                    Dowiedz się więcej
                </span>
            </CookieConsent>
        </Container>
    );
};

export default Modals;
