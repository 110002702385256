import React, { useEffect } from 'react';
import styled from 'styled-components';
import LogoTop from '../../assets/logoTop.webp';
import LogoBottom from '../../assets/logoBottom.webp';
import gsap from 'gsap';
import { TextButton } from '../common/Typography';

const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 15vh;
    position: fixed;
    background-color: #7f778f;
    z-index: 1;
    justify-content: space-between;
    align-content: center;
`;

const LogoWrapper = styled.div`
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-content: center;
    justify-content: center;
    cursor: pointer;
`;

const TopLogo = styled.img`
    height: 40%;
`;

const BottomLogo = styled.img`
    height: 40%;
`;

const MenuWrapper = styled.div`
    margin-right: 4vw;
    display: flex;
`;

interface Props {
    scroller: any;
}

const Navbar = ({ scroller }: Props) => {
    useEffect(() => {
        if (scroller) {
            gsap.timeline({
                duration: 0.5,
                scrollTrigger: {
                    trigger: '#wrapper001',
                    start: '30%',
                    toggleActions: 'play none none reverse',
                },
            })
                .add('navAnim')
                .to(
                    '#nav',
                    {
                        height: '7.5vh',
                    },
                    'navAnim'
                )
                .to(
                    '#l1',
                    {
                        y: '-7.5vh',
                        height: 0,
                    },
                    'navAnim'
                )
                .to(
                    '#l2',
                    {
                        height: '80%',
                    },
                    'navAnim'
                )
                .to(
                    '#itemw',
                    {
                        scale: 0.6,
                    },
                    'navAnim'
                )
                .to(
                    '#askButton',
                    {
                        marginTop: '8vh',
                    },
                    'navAnim'
                );
        }
    }, [scroller]);

    return (
        <Container id={'nav'}>
            <LogoWrapper
                id={'logos'}
                onClick={() => scroller.scrollIntoView(document.getElementById('home'))}>
                <TopLogo id={'l1'} src={LogoTop} alt={'logo'} />
                <BottomLogo id={'l2'} src={LogoBottom} alt={'name'} />
            </LogoWrapper>
            <MenuWrapper id={'itemw'}>
                <TextButton
                    onClick={() => scroller.scrollIntoView(document.getElementById('about'))}
                    id={'item1'}>
                    O MNIE
                </TextButton>
                <TextButton
                    id={'item2'}
                    onClick={() => scroller.scrollIntoView(document.getElementById('offert1'))}>
                    USŁUGI <br></br> KSIĘGOWE
                </TextButton>
                <TextButton
                    id={'item3'}
                    onClick={() => scroller.scrollIntoView(document.getElementById('sp1'))}>
                    USŁUGA <br></br> KADROWO-PŁACOWA
                </TextButton>
                <TextButton
                    id={'item4'}
                    onClick={() => scroller.scrollIntoView(document.getElementById('sp2'))}>
                    ROZLICZENIA <br></br> PODATKOWE
                </TextButton>
                <TextButton
                    id={'item5'}
                    onClick={() => scroller.scrollIntoView(document.getElementById('contact'))}>
                    KONTAKT
                </TextButton>
            </MenuWrapper>
        </Container>
    );
};

export default Navbar;
