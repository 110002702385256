import React, { useEffect } from 'react';
import styled from 'styled-components';
import mainImg from '../../assets/003.webp';
import gsap from 'gsap';

const ImgWrapper = styled.div`
    display: flex;
    width: 140vw;
    height: 50vh;
    overflow: hidden;
`;

const ImgMain = styled.img`
    display: flex;
    width: 140%;
    height: 140%;
    object-fit: cover;
`;

interface Props {
    scroller: any;
}

const SecondImage = ({ scroller }: Props) => {
    useEffect(() => {
        if (scroller) {
            const container = document.getElementById('offert1');

            gsap.to('#img003', {
                xPercent: -20,
                scrollTrigger: {
                    trigger: container,
                    endTrigger: '#sp1',
                    scrub: true,
                },
            });

            gsap.to('#img003', {
                yPercent: -40,
                scrollTrigger: {
                    trigger: '#contact',
                    scrub: true,
                },
            });
        }
    }, [scroller]);

    return (
        <ImgWrapper id={'imw'}>
            <ImgMain id={'img003'} src={mainImg} alt={'003'} />
        </ImgWrapper>
    );
};

export default SecondImage;
