import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Offer from './components/home/Offer';
import Scrollbar from 'smooth-scrollbar';
import About from './components/home/About';
import Contact from './components/home/Contact';
import { fonts } from './assets/fonts/fonts';
import Modals from './components/home/Modals';
import Calculations from './components/home/Calculations';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #cdcbd1;
    font-family: ${fonts.main};
`;

const Spacer2 = styled.div`
    height: 100vh;
    position: relative;
`;

function App() {
    const ref = useRef<HTMLDivElement>(null);
    gsap.registerPlugin(ScrollTrigger);
    const [scroller, setScroller] = useState<any>(null);
    const [scrollableHeight, setScrollableHeight] = useState(0);
    const [openCookies, setOpenCookies] = useState(false);
    const [openRODO, setOpenRODO] = useState(false);

    const snapPoints: string[] = ['#home', '#about', '#offert1', '#sp1', '#sp2', '#contact'];

    useEffect(() => {
        const scrollbar = Scrollbar.init(ref.current!, {});

        ScrollTrigger.scrollerProxy(ref.current, {
            scrollTop(value?: number) {
                if (value) {
                    scrollbar.scrollTop = value;
                }
                return scrollbar.scrollTop;
            },
        });
        const listener = () => ScrollTrigger.update();
        scrollbar.addListener(listener);
        scrollbar.setPosition(0, 0);

        ScrollTrigger.defaults({ scroller: ref.current });
        ScrollTrigger.update();
        setScroller(scrollbar);

        const scrollerContent = document.querySelector<HTMLDivElement>('.scroll-content');
        if (scrollerContent) setScrollableHeight(scrollerContent.offsetHeight - window.innerHeight);
    }, []);

    useEffect(() => {
        if (scrollableHeight > 0) {
            const points = snapPoints
                .map(elemId => document.querySelector<HTMLDivElement>(elemId))
                .filter(elem => !!elem)
                .map(elem => elem!.offsetTop + (elem!.offsetHeight - window.innerHeight) / 2)
                .map(adjOffset => adjOffset / scrollableHeight);

            ScrollTrigger.create({
                snap: {
                    snapTo: [...points, 1],
                    duration: { min: 0.2, max: 0.8 },
                    delay: 0,
                    directional: false,
                },
            });
        }
    }, [scrollableHeight]);

    return (
        <>
            <Navbar scroller={scroller} />
            <Modals
                openCookies={openCookies}
                openRODO={openRODO}
                setOpenCookies={setOpenCookies}
                setOpenRODO={setOpenRODO}
            />
            <Calculations />
            <Container id={'app_cont'} ref={ref}>
                <Home scroller={scroller} />
                <About scroller={scroller} />
                <Offer scroller={scroller} />
                <Spacer2 id={'sp1'} />
                <Spacer2 id={'sp2'} />
                <Contact setOpenRODO={setOpenRODO} setOpenCookies={setOpenCookies} />
            </Container>
        </>
    );
}

export default App;
