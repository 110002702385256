import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { Header, Paragraph, StyledLi } from '../common/Typography';

const Content = styled.div`
    display: flex;
    position: relative;
    align-content: center;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
`;

const Wrapper = styled.div`
    align-self: center;
    width: 40%;
    margin-top: 14vh;
`;

interface Props {
    scroller: any;
}

const About = ({ scroller }: Props) => {
    useEffect(() => {
        if (scroller) {
            gsap.set('.animIn', { opacity: 0, y: 100 });

            ScrollTrigger.batch('.animIn', {
                start: 'top 80%',
                onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.2 }),
                onLeaveBack: batch => gsap.to(batch, { opacity: 0, y: 100, overwrite: true }),
            });
        }
    }, [scroller]);
    return (
        <Content id={'about'}>
            <Wrapper>
                <Header className={'animIn'}>O mnie</Header>
                <Paragraph className={'animIn'}>
                    Swoją przygodę z księgowością rozpoczęłam w Wielospecjalistycznym Szpitalu –
                    SPZOZ w Zgorzelcu najpierw na stanowisku księgowy, a później jako specjalista
                    ds. księgowości. Tam miałam okazję zdobywać doświadczenie zawodowe pod okiem
                    specjalistów z zakresu rachunkowości
                </Paragraph>
                <Paragraph className={'animIn'}>
                    W sierpniu 2019 roku otworzyłam własne biuro rachunkowe we Wrocławiu. Jest to
                    dynamicznie rozwijające się biuro, świadczące szeroki zakres usług z pełnym
                    zaangażowaniem w sprawy klienta. Oferuję profesjonalną obsługę na wysokim
                    poziomie, a także dbam o partnerskie relacje z moimi klientami.
                </Paragraph>
                <Paragraph className={'animIn'}>
                    MB Biuro Usług Księgowych to nowoczesne biuro nastawione na pełne zaangażowanie
                    w rozwiązywanie problemów, zapewniając przy tym najwyższy standard usług. Dzięki
                    wiedzy, systematycznym podnoszeniem kwalifikacji zawodowych, a także
                    monitorowaniu stale zmieniających się przepisów prawnych, jestem w stanie
                    świadczyć usługi na najwyższym poziomie w pełni zgodnym z aktualnymi
                    rozporządzeniami.
                </Paragraph>
                <Paragraph className={'animIn'}>
                    Zaufało mi już wielu klientów m.in. z branż medycznych, handlowych,
                    transportowych, IT, doradztwa prawnego, finansowego i wielu innych.
                </Paragraph>
                <Paragraph className={'animIn'}>
                    W celu zapewnienia bezpieczeństwa sobie i moim klientom posiadam ubezpieczenie
                    zakresie prowadzonej działalności gospodarczej:
                    <ol type={'a'}>
                        <StyledLi>
                            obowiązkowe ubezpieczenie odpowiedzialności cywilnej przedsiębiorstw,
                        </StyledLi>
                        <StyledLi>
                            wykonujących działalność z zakresu usługowego prowadzenia ksiąg
                            rachunkowych,
                        </StyledLi>
                        <StyledLi>
                            uzupełniające i nadwyżkowe ubezpieczenie odpowiedzialności cywilnej z
                            tytułu usługowego prowadzenia ksiąg rachunkowych i doradztwa
                            podatkowego,
                        </StyledLi>
                        <StyledLi>
                            ubezpieczenie odpowiedzialności cywilnej z tytułu doradztwa w zakresie
                            ubezpieczeń społecznych i innego doradztwa kadrowego,
                        </StyledLi>
                        <StyledLi>
                            ubezpieczenie osobistej odpowiedzialności karno-skarbowej.
                        </StyledLi>
                    </ol>
                </Paragraph>
            </Wrapper>
        </Content>
    );
};

export default About;
