import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 5vh;
    width: 80%;
    height: 30vh;
`;

const LocationMap = () => {
    return (
        <Container className={'animIn'}>
            <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24153.915556679083!2d16.919370296405287!3d51.15515409668728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470feaeeffb82099%3A0x3384858a3baa956c!2sAleja%20%C5%9Aliwowa%207b%2C%2054-107%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1665680303085!5m2!1spl!2spl'
                width='100%'
                height='100%'
                style={{ border: '0' }}
                loading='lazy'></iframe>
        </Container>
    );
};

export default LocationMap;
