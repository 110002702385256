import React, { useState } from 'react';
import styled from 'styled-components';
import { fonts } from '../../assets/fonts/fonts';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import { Paragraph, SubmitButton } from '../common/Typography';
import axios from 'axios';

const Container = styled.div<{ open: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 95vh;
    width: 90vw;
    background-color: ${props => (props.open ? '#cdcbd1' : '#7f778f')};
    transition-duration: 0.5s;
    transform: translateX(${props => (props.open ? 0 : -100)}%);
    display: flex;
    border-radius: 1vh;
    flex-direction: row;
`;

const StyledTextButton = styled.div<{ open: boolean }>`
    font-size: 2vh;
    margin: 0;
    padding: 0.7vh;
    font-family: ${fonts.main};
    display: flex;
    height: fit-content;
    color: ${props => (props.open ? '#4d4b4b' : '#afafb6')};
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: ${props => (props.open ? '#8c8c8c' : '#dfdfe1')};
    }
`;

const InputContainer = styled.div`
    width: 80%;
    padding: 3vh;
`;

const ManageIconWrapper = styled.div<{ open: boolean }>`
    margin-top: 16vh;
    margin-left: auto;
    margin-right: -3.5vh;
    display: flex;
    z-index: 11;
    width: fit-content;
    height: fit-content;
    justify-content: right;
    border-radius: 0 8px 8px 0;
    background-color: ${props => (props.open ? '#cdcbd1' : '#7f778f')};
    writing-mode: vertical-rl;
`;

const StyledPara = styled(Paragraph)`
    color: #000;
    min-height: 2vh;
    margin-bottom: 1vh;
`;

const Calculations = () => {
    const [open, setOpen] = useState(false);
    const [orgForm, setOrgForm] = useState('');
    const [serviceForm, setServiceForm] = useState('');
    const [rollForm, setRollForm] = useState('');
    const [taxForm, setTaxForm] = useState('');
    const [sell, setSell] = useState('');
    const [buy, setBuy] = useState('');
    const [docs, setDocs] = useState('');
    const [stuff, setStuff] = useState('');
    const [vat, setVat] = useState('tak');
    const [payroll, setPayroll] = useState('tak');
    const [contact, setContact] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [send, setSend] = useState(false);

    const validate = () => {
        return (
            orgForm !== '' &&
            serviceForm !== '' &&
            rollForm !== '' &&
            taxForm !== '' &&
            sell !== '' &&
            buy !== '' &&
            docs !== '' &&
            contact !== '' &&
            mail !== ''
        );
    };

    const onSend = () => {
        if (validate()) {
            setSend(true);
            if (!send) {
                document.body.style.cursor = 'wait';
                setResultMessage('');
                axios
                    .post('https://m-bender.pl/php/mailer_valuation.php', {
                        organizationForms: orgForm,
                        servicesForms: serviceForm,
                        rollForms: rollForm,
                        taxationForms: taxForm,
                        vatRadio: vat,
                        payrollRadio: payroll,
                        sellInvoices: sell,
                        buyInvoices: buy,
                        documents: docs,
                        employees: stuff,
                        message: message,
                        name: contact,
                        email: mail,
                        phone: phone,
                    })
                    .then(() => setResultMessage('Twoja wiadomość została wysłana'))
                    .catch(err => {
                        setResultMessage('Coś poszło nie tak. Spróbuj ponownie później');
                        console.log(err);
                    })
                    .finally(() => (document.body.style.cursor = 'default'));
            }
        } else setResultMessage('Uzupełnij wszytskie pola oznaczone *');
    };

    return (
        <Container open={open}>
            <InputContainer>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <InputLabel>Forma organizacyjno-prawna *</InputLabel>
                    <Select
                        id='organizationForms'
                        label='Forma organizacyjno-prawna'
                        onChange={e => setOrgForm(e.target.value)}
                        value={orgForm}>
                        <MenuItem value={'osoba fizyczna'}>osoba fizyczna</MenuItem>
                        <MenuItem value={'spółka kapitałowa'}>spółka kapitałowa</MenuItem>
                        <MenuItem value={'spółka osobowa'}>spółka osobowa</MenuItem>
                        <MenuItem value={'inna'}>inna</MenuItem>
                        <MenuItem value={'nie wiem'}>nie wiem</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <InputLabel>Rodzaj usługi *</InputLabel>
                    <Select
                        id='servicesForms'
                        label='Rodzaj usługi'
                        onChange={e => setServiceForm(e.target.value)}
                        value={serviceForm}>
                        <MenuItem value={'handel'}>handel</MenuItem>
                        <MenuItem value={'produkcja'}>produkcja</MenuItem>
                        <MenuItem value={'usługi'}>usługi</MenuItem>
                        <MenuItem value={'inna'}>inna</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <InputLabel>Rodzaj ewidencji *</InputLabel>
                    <Select
                        id='rollForms'
                        label='Rodzaj ewidencji'
                        onChange={e => setRollForm(e.target.value)}
                        value={rollForm}>
                        <MenuItem value={'księga przychodów i rozchodów'}>
                            osoba księga przychodów i rozchodów
                        </MenuItem>
                        <MenuItem value={'ryczałt ewidencjonowany'}>
                            ryczałt ewidencjonowany
                        </MenuItem>
                        <MenuItem value={'najem lub dzierżawa'}>najem lub dzierżawa</MenuItem>
                        <MenuItem value={'księga rachunkowa'}>księga rachunkowa</MenuItem>
                        <MenuItem value={'nie wiem'}>nie wiem</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <InputLabel>Forma opodatkowania *</InputLabel>
                    <Select
                        id='taxationForms'
                        label='Forma opodatkowania'
                        onChange={e => setTaxForm(e.target.value)}
                        value={taxForm}>
                        <MenuItem value={'zasady ogólne'}>zasady ogólne</MenuItem>
                        <MenuItem value={'podatek liniowy'}>podatek liniowy</MenuItem>
                        <MenuItem value={'ryczałt'}>ryczałt</MenuItem>
                        <MenuItem value={'podatek od osób prawnych'}>
                            podatek od osób prawnych
                        </MenuItem>
                        <MenuItem value={'nie wiem'}>nie wiem</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        type={'number'}
                        id='sellInvoices'
                        label='Ilość faktur sprzedaży w miesiącu'
                        value={sell}
                        onChange={e => setSell(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        type={'number'}
                        id='buyInvoices'
                        label='Ilość faktur zakupu w miesiącu'
                        value={buy}
                        onChange={e => setBuy(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        type={'number'}
                        id='docsCount'
                        label='Ilość pozostałych dokumentów'
                        value={docs}
                        onChange={e => setDocs(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        type={'number'}
                        id='stuff'
                        label='Ilość zatrudnionych osób'
                        value={stuff}
                        onChange={e => setStuff(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl style={{ marginBottom: '2vh' }}>
                    <FormLabel id='vatRadio'>Firma jest podatnikiem VAT</FormLabel>
                    <RadioGroup
                        aria-labelledby='vatRadio'
                        defaultValue={vat}
                        name='vatRadio'
                        onChange={e => setVat(e.target.value)}>
                        <FormControlLabel value='tak' control={<Radio />} label='Tak' />
                        <FormControlLabel value='nie' control={<Radio />} label='Nie' />
                    </RadioGroup>
                </FormControl>
                <FormControl style={{ marginBottom: '2vh', marginLeft: '2vw' }}>
                    <FormLabel id='payrollRatio'>Obsługa kadrowo-płacowa</FormLabel>
                    <RadioGroup
                        aria-labelledby='payrollRatio'
                        defaultValue={payroll}
                        name='payrollRatio'
                        onChange={e => setPayroll(e.target.value)}>
                        <FormControlLabel value='tak' control={<Radio />} label='Tak' />
                        <FormControlLabel value='nie' control={<Radio />} label='Nie' />
                    </RadioGroup>
                </FormControl>
            </InputContainer>
            <InputContainer>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        id='contact-input'
                        label='Osoba kontaktowa'
                        value={contact}
                        onChange={e => setContact(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        type={'email'}
                        id='email-input'
                        label='E-mail'
                        value={mail}
                        onChange={e => setMail(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        id='phone-input'
                        label='Telefon'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: '2vh' }}>
                    <TextField
                        id='message-input'
                        label='Komentarz/uwagi'
                        rows={10}
                        multiline
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    />
                </FormControl>
                <StyledPara>{resultMessage}</StyledPara>
                <SubmitButton disabled={send} onClick={onSend}>
                    Wyślij
                </SubmitButton>
            </InputContainer>
            <ManageIconWrapper open={open} id={'askButton'}>
                <StyledTextButton open={open} onClick={() => setOpen(!open)}>
                    Zapytaj o cenę
                </StyledTextButton>
            </ManageIconWrapper>
        </Container>
    );
};

export default Calculations;
